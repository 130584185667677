<template>
  <div class="text-left p-5">
    <div class="fs-3 fw-bolder py-2">Refund Policy</div>
    <div class="pt-3">
      In accordance with our Terms of Use, fees paid, Credits purchased and Credits spent
      are generally not refundable. However, we may make refunds at our sole discretion. If
      you would like a refund, please write us to <a href="mailto:info@zemeto.co">info@zemeto.co</a>.
    </div>

    <div class="fs-5 fw-bold pt-5">Credits Refund</div>
    <div class="pt-3">We may refund Credits spent by you back to your Credits account if:</div>
    <div class="pt-3">
      <b>Scam:</b> you have been scammed by a Member or by a third person on {{ siteUrl }}.
      Please see the Note on Scam below.
    </div>
    <div class="pt-3">
      <b>Other:</b> we have not provided to you the Services that you paid for, in violation of our
      Terms of Use and the promises to you on the Website.
    </div>
    <div class="pt-3">
      <b>Note on Scam:</b> While we are taking steps to reduce the possibility of scam on {{ siteUrl }},
      meeting new people on the Internet always carries the possibility of scam.
      If you have been scammed, we may decide to refund Credits spent by you while
      communicating with a Member involved in the scam. However, this would be your sole
      remedy against us.
    </div>

    <div class="fs-5 fw-bold pt-5">Money Refund</div>
    <div class="pt-3">
      If your account is permanently closed, you will be refunded the value of any unused
      Credits for which you have previously paid, in accordance with the Terms of Use.
    </div>

    <div class="fs-5 fw-bold pt-5">Refund Procedure</div>
    <div class="pt-3">
      If you believe that you are entitled to a refund, please contact us at <a href="mailto:info@zemeto.co">info@zemeto.co</a>.
      Please accompany your request with the relevant information and any supporting
      evidence.
      Should you dishonestly apply to your bank, card issuer or payment service provider for a
      chargeback without first following our refund procedure, we may be entitled to bring legal
      action against you to recover from you any resulting losses that we may suffer.
    </div>
  </div>
</template>

<script>
import config from '@/config';

export default {
  beforeMount() {
    this.initClarity(config.clarityId.onboardingId)
  },
  computed: {
    siteUrl() {
      return config.siteHost;
    }
  },
  methods:{
    scrollToTop() {
      window.scrollTo(0,0);
    },
  },
  mounted() {
    this.scrollToTop();
  },
}
</script>

<style lang="scss" scoped>
.text-left {
  text-align: left;
}
</style>
